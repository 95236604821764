/**
 * Container element for the game, partially handling game state
 * and showing the game over screen
 */

import { html, LitElement } from "lit";
import { customElement } from "lit/decorators";
import { HexakaiGameSession } from "../models/hexakai-game-session";

enum DisplayMode {
    gameComplete,
    gameOngoing
}

@customElement('hexakai-game')
export class HexakaiGame extends LitElement {

    private displayMode = DisplayMode.gameOngoing;
    private session!: HexakaiGameSession;
    private gameDuration!: number;
    private boardUrl!: string;

    protected createRenderRoot(): HTMLElement | DocumentFragment {
        return this;
    }

    render() {
        if (this.displayMode === DisplayMode.gameOngoing) {
            return html`
                <hexakai-board
                    id="primary-game"
                    mode="game"
                    @game-complete="${(e: any) => this.onGameComplete(e.detail)}"
                ></hexakai-board>
            `;
        } else {
            return html`
                <style>
                    hexakai-game-complete {
                        padding: 5%;
                        width: 90%;
                        height: 90% !important;

                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        font-size: 120%;
                    }

                    @media screen and (max-width: 700px) {
                        hexakai-game-complete {
                            font-size: 90%;
                            width: 92%;
                        }
                    }
                </style>
                <hexakai-game-complete
                    .params="${this.session.params}"
                    .gameDuration="${this.gameDuration}"
                    .boardUrl="${this.boardUrl}"
                    .dailyPuzzleTimestamp="${this.session.dailyPuzzleTimestamp}"
                    @play-again="${() => this.onPlayAgain()}"
                ></hexakai-game-complete>
            `;
        }
    }

    private onGameComplete(data: any): void {
        const {session, boardUrl, gameDuration} = data;
        console.log(`[HexakaiGame] game complete`, data);
        this.session = session;
        this.gameDuration = gameDuration;
        this.displayMode = DisplayMode.gameComplete;
        this.boardUrl = boardUrl;
        this.requestUpdate();
    }

    private onPlayAgain(): void {
        this.displayMode = DisplayMode.gameOngoing;
        this.requestUpdate();
    }
}