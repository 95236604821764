import { css, html, LitElement } from "lit";
import { customElement, property } from "lit/decorators";
import { HexakaiGameParams } from "../models/hexakai-game-params";
import { formatDuration } from "../format-duration/duration-formatter";
import { GameAchievementService } from "../achievements/game-achievement-service";
import { ShareService } from "../share-service/share-service";
import { ClientService } from "../client-service/client-service";
import { ModalService } from "../modal-service/modal-service";
import { UserEventsServiceComposite } from "../user-events/user-events-service-composite";
import { LocalStorageService } from "../local-storage/local-storage-service";
import { playAgainClickedEvent } from "../user-events/events/play-again-clicked-event";
import { GoogleAdsProviderService } from "../ads-service/google/google-ads-service";
import { EventListenerEventsService } from "../user-events/event-listener-events-service";
import { SessionService } from "../session-service/session-service";
import { UserMetrics } from "../models/user-metrics";

@customElement('hexakai-game-complete')
export class HexakaiGameCompleteComponent extends LitElement {

    static styles = css`
    
        .container {
            display: flex;
            text-align: left;
            align-items: center;
            justify-content: center;
        }

        .container img-smart {
            width: 130px;
            /* height: 80px; */
            margin-right: 15px;
            aspect-ratio: 1;
        }

        .buttons-row {
            display: flex;
            width: 100%;
            justify-content: center;

            flex-wrap: wrap;
        }

        .buttons-row styled-button {
            margin: 10px;                   
        }

        h1 {
            margin-top: 0;
        }

        p {
            margin-top: 0.1em;
            margin-bottom: 0.1em;
        }

        .congrats {
            font-size: 1.1em;
        }

        .streak-date {
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 2.5em;
            height: 2.7em;
            margin: 0.2em;
            padding: 0.5em;
            border: 1px solid var(--hex-hover-color); /* Border color */
            background-color: var(--underlay-color); /* Background color */
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3); /* Subtle shadow for depth */
            color: var(--body-color); /* Text color */
            font-family: inherit; /* Use existing font */
            text-align: center;
        }

        .date-list-day-of-week {
            font-size: 80%;
            font-weight: bold;
            margin-bottom: 5px;
            color: var(--button-hover-background-color); /* Lighter color for the weekday */
        }

        .date-list-day {
            /*font-size: 18px;*/
            font-weight: bold;
            color: var(--body-color); /* Main text color */
        }

        .date-list-day-of-week-tomorrow {
            color: var(--body-color) !important;
        }

        .streak-date-tomorrow {
            border: 2px solid var(--hex-good-color) !important; /* Bright border color to highlight */
            background-color: var(--hex-reset-color) !important; /* Slightly different background */
            color: var(--body-color) !important; /* Text color */
        }

        .streak-date-fade {
            opacity: 0.5;
        }

        .google-ads-container {
            width: 100%;
            height: fill-available;
            height: -webkit-fill-available;
        }

        @media screen and (max-width: 800px) {
            .buttons-row styled-button {
                margin: 5px;              
            }

            .container img-smart {
                width: 80px;
            }
        }
    `;

    createRenderRoot() {
        return this;
    }

    private clientService = new ClientService();
    private modalService = new ModalService();
    private shareService = new ShareService(
        this.clientService,
        this.modalService
    );
    private storageService = new LocalStorageService();
    private userEventServiceComposite = new UserEventsServiceComposite(
        this.clientService,
        this.storageService
    );
    private userEventsServiceComposite = new UserEventsServiceComposite(
        this.clientService,
        this.storageService
    );

    private eventListenerEventsService = new EventListenerEventsService();

    private sessionService = new SessionService(
        this.clientService,
        this.modalService,
        this.storageService,
        this.eventListenerEventsService
    );

    @property() params!: HexakaiGameParams;
    @property() gameDuration!: number;
    @property() boardUrl!: string;
    @property() dailyPuzzleTimestamp!: number;

    private metrics!: UserMetrics;

    connectedCallback(): void {
        super.connectedCallback();
        this.sessionService.getUserMetrics().then(metrics => {
            this.metrics = metrics;
            this.requestUpdate();
        });
    }

    render() {
        if (!this.params) {
            return html``;
        }

        const achievement = GameAchievementService.getAchievementFromGame(
            this.params.gameSize,
            this.params.difficulty
        );

        const contentTimeCompleteText = this.gameDuration
            ? html`<p>Your play time was ${formatDuration(this.gameDuration)}.</p>`
            : '';

        const adMarkup = this.clientService.getConfig().body.gameCompleteAdVisible
            ? html`
                    <br/>
                    <hr style="width:95%"/>
                    <br/>
                    ${GoogleAdsProviderService.getAdMarkup(GoogleAdsProviderService.AD_UNITS.primaryHorizontal)}
                `
            : '';

        const streakDays = this.clientService.getConfig().gameComplete.mockStreak
            ? 14
            : this.metrics?.streaks.boardCompleted.numDays;
        
        let streakVisual: any[] = [];
        if (streakDays) {
            const tomorrow = (this.clientService.getConfig().gameComplete.mockStreak && this.dailyPuzzleTimestamp)
                ? new Date(this.dailyPuzzleTimestamp + 1_000 * 60 * 60 * 24)
                : this.getTomorrow();
            const dateList = this.getDatesList(tomorrow, streakDays);
            let startIndex = Math.max(0, dateList.length - 7);
            for (let i = startIndex; i < dateList.length; i++) {
                const mainClasses = [
                    "streak-date",
                    i === dateList.length - 1
                        ? "streak-date-tomorrow"
                        : "",
                    i === startIndex && startIndex > 0
                        ? "streak-date-fade"
                        : ""
                ];

                const dayOfWeekClasses = [
                    "date-list-day-of-week",
                    i === dateList.length - 1
                        ? "date-list-day-of-week-tomorrow"
                        : "",
                ];

                streakVisual.push(html`
                    <div class="${mainClasses.join(" ")}">
                        <p class="${dayOfWeekClasses.join(" ")}">${dateList[i].dayOfWeek}</p>
                        <p class="date-list-day">${dateList[i].day}</p>
                    </div>
                `);
            }
        }

        if (adMarkup) {
            GoogleAdsProviderService.attachGoogleAdsScript(GoogleAdsProviderService.AD_UNITS.fixed400);
            setTimeout(() => {
                // TODO: hacky, find better way of encapsulating this
                ((window as any).adsbygoogle = (window as any).adsbygoogle || []).push({});
            }, 150);
        }

        // TODO: randomize title
        return html`
            <style>
                ${HexakaiGameCompleteComponent.styles}
            </style>
            <h1>Puzzle Completed!</h1>
            <div class="container">
                <img-smart src="${achievement.iconUrl}"></img-smart>
                <div>
                    <p class="congrats"><b>Congrats one the win!</b></p>
                    ${contentTimeCompleteText}
                    <p>
                        This puzzle was size ${this.params.gameSize}
                        rated at ${this.params.difficulty} difficulty.
                    </p>
                </div>
            </div>
            <br/>
            <hr style="width: 95%" />
            <h2>Keep the Streak Alive!</h2>
            <p>
                You're on a ${streakDays} day playing streak.
            </p>
            <p>
                Solve a new board every day and see how high you can take it!
            </p>
            <br/>
            <div class="streak-visual-container">
                ${streakVisual}
            </div>
            <br/>
            <div class="buttons-row">
                <styled-button @click=${() => this.share()}>Share</styled-button>
                <styled-button @click=${() => this.onPlayAgain()}>Play Again!</styled-button>
                <styled-button @click=${() => {
                window.location.href = '/daily-puzzles';
            }}>Daily Puzzles</styled-button>
            </div>
            ${adMarkup}
     
        `;
    }

    private share(): void {
        const shareTimeText = this.gameDuration
            ? ` in ${formatDuration(this.gameDuration)}`
            : '';

        const title = "Hexakai";
        const text = `I've just solved a Hexakai board of size ${this.params.gameSize} on ${this.params.difficulty} mode${shareTimeText}!
            
Here's the puzzle I just played:`;

        this.shareService.share(
            title,
            text,
            this.boardUrl,
            "Share Your Win!"
        );
    }

    private onPlayAgain(): void {
        this.userEventsServiceComposite.logEvent(
            playAgainClickedEvent(this.params)
        );
        this.dispatchEvent(
            new CustomEvent('play-again', {})
        );
    }

    private getDatesList(
        targetDate: Date,
        numDays: number
    ): { dayOfWeek: string; day: string }[] {
        const dates: { dayOfWeek: string; day: string }[] = [];

        for (let i = numDays; i >= 0; i--) {
            const currentDate = new Date(targetDate);
            currentDate.setDate(targetDate.getDate() - i);

            const dayOfWeek = currentDate.toLocaleString('en-US', { weekday: 'short' }); // Abbreviated weekday
            const day = currentDate.toLocaleString('en-US', { day: '2-digit' }); // Day only, zero-padded

            dates.push({ dayOfWeek, day });
        }

        return dates;
    }

    private getTomorrow(): Date {
        const now = new Date();
        // Get the current date's local time at midnight
        const localMidnight = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        // Add one day to get tomorrow
        localMidnight.setDate(localMidnight.getDate() + 1);

        return localMidnight;
    }
}